/**
 * Maps are extensively used.
 * To avoid performance issues, simple services are created instead of Vue components
 */

import * as L from 'leaflet';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import screenfull from 'screenfull';
import 'leaflet/dist/leaflet.css';
import polylineutils from '@mapbox/polyline';

// just JS things... https://github.com/brunob/leaflet.fullscreen/issues/81
window.screenfull = screenfull;

export default {
  map: null,
  polylines: [],
  popup: null,
  init(containerId, lat, lng, zoom) {
    const fullScreenOptions = {
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topleft',
      },
    };
    this.map = L.map(containerId, fullScreenOptions).setView([lat, lng], zoom);
    const osmLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Map data  <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
    });
    osmLayer.addTo(this.map);
  },
  addEncodedPolyline(encodedPolyline, color) {
    const latlngs = polylineutils.decode(encodedPolyline);
    const polylineObj = L.polyline(latlngs, { color });
    this.polylines.push(polylineObj);
    polylineObj.addTo(this.map);
    return polylineObj;
  },
  bindViewToPolyline(polylineObj) {
    this.map.fitBounds(polylineObj.getBounds());
  },
  addPopup(clickableObj, content) {
    clickableObj.bindPopup(content);
  },
  clear() {
    this.polylines.forEach((polylineObj) => polylineObj.remove(this.map));
    this.polylines = [];
  },
  destroy() {
    this.map.remove();
    this.map = null;
  },
};
