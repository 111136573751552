import polylineutils from '@mapbox/polyline';

class AsyncLoader {
  scriptSrc = 'https://api.mapy.cz/loader.js';

  waitInterval = 50;

  waitTimeout = 5000;

  constructor() {
    if (typeof window === 'undefined') {
      throw new Error('mapy cz loader is supported only in browser environment');
    }
  }

  getApi() {
    if (window.SMap) {
      return new Promise((resolve) => resolve(window.SMap));
    }

    if (!this.loaderPromise) {
      this.loaderPromise = this.load();
    }

    return this.loaderPromise;
  }

  load() {
    return new Promise((resolve) => {
      this.appendScript();
      this.waitFor(() => window.Loader).then((Loader) => {
        // eslint-disable-next-line no-param-reassign
        Loader.async = true;
        Loader.load(null, null, () => {
          resolve(window.SMap);
        });
      });
    });
  }

  appendScript() {
    const script = document.createElement('script');
    script.src = this.scriptSrc;
    script.async = true;
    document.head.appendChild(script);
  }

  async waitFor(callback) {
    let elapsed = 0;
    do {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, this.waitInterval));
      elapsed += this.waitInterval;
      if (elapsed > this.waitTimeout) {
        throw new Error('Mapy CZ Loader Timeout');
      }
    } while (!callback());
    return callback();
  }
}

const asyncLoader = new AsyncLoader();

export default {
  API: null,
  geoLayer: null,
  async init(containerId, lat, lng, zoom) {
    if (!this.API) {
      try {
        this.API = await asyncLoader.getApi();
      } catch (err) {
        console.error(err);
        // loader uses document.write to add scripts which may be blocked
        document.getElementById(containerId).innerHTML = 'Unable to load Mapy CZ, try reloading the page.';
      }
    }
    this.setupMap(containerId, lat, lng, zoom);
    this.setupFullScreenButton(containerId);
  },
  setupMap(containerId, lat, lng, zoom) {
    const SMap = this.API;
    const center = SMap.Coords.fromWGS84(lng, lat);
    this.map = new SMap(document.getElementById(containerId), center, zoom);
    this.map.addDefaultLayer(SMap.DEF_TURIST).enable();
    this.map.addDefaultControls();
    this.geoLayer = new SMap.Layer.Geometry();
    this.map.addLayer(this.geoLayer);
    this.geoLayer.enable();
  },
  setupFullScreenButton(containerId) {
    const className = 'custom-full-screen-toggle';

    const mapEl = document.getElementById(containerId);
    if (mapEl.getElementsByClassName(className)[0]) {
      return;
    }
    if (!document.fullscreenEnabled) {
      return;
    }

    const controlsElement = document.createElement('div');
    controlsElement.classList.add(className);

    mapEl.addEventListener('fullscreenchange', () => {
      // not great, not terrible
      setTimeout(() => this.map.syncPort(), 1000);
    });
    controlsElement.addEventListener('click', () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        mapEl.requestFullscreen();
      }
    });

    mapEl.append(controlsElement);
  },
  addEncodedPolyline(encodedPolyline, color) {
    const SMap = this.API;
    const points = polylineutils
      .decode(encodedPolyline)
      .map(([lat, lng]) => SMap.Coords.fromWGS84(lng, lat));
    const options = {
      color,
      width: 2,
      outlineColor: '#000',
      outlineWidth: 1,
      outlineOpacity: 0.5,
    };
    const polyline = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, points, options);
    this.geoLayer.addGeometry(polyline);
    return polyline;
  },
  bindViewToPolyline(polylineObj) {
    const [center, zoom] = polylineObj.computeCenterZoom(this.map);
    this.map.setCenterZoom(center, zoom);
  },
  addPopup(clickableObj, content) {
    const SMap = this.API;
    const card = new SMap.Card();
    card.getBody().innerHTML = content;
    clickableObj.decorate(SMap.Geometry.Feature.Card, card);
  },
  clear() {
    if (this.geoLayer) {
      this.geoLayer.removeAll();
    }
  },
  destroy() {
    if (this.map) {
      this.map.$destructor();
    }
  },
};
