import osm from '@/services/map/osm';
import mapycz from '@/services/map/mapycz';
import gmaps from '@/services/map/google';
import store from '@/store/index';

export default async function () {
  const profile = await store.dispatch('profile/get');
  switch (profile.settings.mapProvider) {
    case 'gmaps':
      return gmaps;
    case 'mapycz':
      return mapycz;
    default:
      return osm;
  }
}
