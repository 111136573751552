<template>
  <div class="columns is-mobile is-vcentered">
    <div class="column is-three-fifths">
        <h1 class="title">{{ title }}</h1>
    </div>
    <div class="column is-two-fifths has-text-right">
      <progress v-if="isLoading" class="progress is-inline-flex" :value="progress" max="1">
      </progress>
    </div>
  </div>
  <div class="level">
    <div class="level-left">
      <div class="lever-item mb-2">
        <div class="is-align-items-center is-flex">
          <input
           type="checkbox"
           :checked="dateAfterEnabled"
           @input="updateDateAfterEnabled"
          />
          <input
            type="date"
            class="input ml-1"
            placeholder="yyyy-mm-dd"
            :value="inputDateAfter"
            :max="inputDateAfterMax"
            :disabled="!dateAfterEnabled"
            @change="updateDateAfter"
          />
          <a class="ml-1" href="#" @click.stop="setPrevMonth" >&darr;</a>
        </div>
      </div>
      <p class="lever-item ml-3 mr-3 mb-2 dates-separator">&ndash;</p>
      <div class="lever-item mb-2">
        <div class="is-align-items-center is-flex">
          <input
            type="checkbox"
            :checked="dateBeforeEnabled"
            @input="updateDateBeforeEnabled"
          />
          <input
            type="date"
            class="input ml-1"
            placeholder="yyyy-mm-dd"
            :value="inputDateBefore"
            :disabled="!dateBeforeEnabled"
            :min="inputDateBeforeMin"
            @change="updateDateBefore"
          />
          <a class="ml-1" href="#" @click.stop="setNextMonth" >&uarr;</a>
        </div>
      </div>
      <div class="lever-item mb-2">
        <div class="is-align-items-center is-flex">
          <span class="ml-1">Sport</span>
          <SportPicker :sport="sport" @selected="updateSport" />
        </div>
      </div>
      <p class="level-item ml-3 mb-2">
        <button
          v-if="isLoading && isCancellable"
          class="button is-warning"
          @click.stop="handleCancel"
          :disabled="isPending"
        >
          Cancel
        </button>
        <button
          v-else
          class="button is-primary"
          @click.stop="handleApply"
          :disabled="isPending"
        >
          Apply
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import SportPicker from '@/components/SportPicker.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'WorkoutFilter',
  props: ['title'],
  emits: ['apply', 'cancel'],
  components: {
    SportPicker,
  },
  computed: {
    ...mapState('workoutFilter', [
      'dateAfterEnabled',
      'dateAfter',
      'dateBeforeEnabled',
      'dateBefore',
      'sport',
      'isLoading',
      'isPending',
      'isCancellable',
      'progress',
    ]),
    ...mapGetters('workoutFilter', [
      'inputDateAfter',
      'inputDateAfterMax',
      'inputDateBefore',
      'inputDateBeforeMin',
    ]),
  },
  methods: {
    setPrevMonth() {
      this.$store.dispatch('workoutFilter/setPrevMonth');
    },
    setNextMonth() {
      this.$store.dispatch('workoutFilter/setNextMonth');
    },
    updateDateAfterEnabled(e) {
      this.$store.dispatch('workoutFilter/setDateAfterEnabled', e.target.checked);
    },
    updateDateBeforeEnabled(e) {
      this.$store.dispatch('workoutFilter/setDateBeforeEnabled', e.target.checked);
    },
    updateDateAfter(e) {
      const { target: { value } } = e;
      this.$store.dispatch('workoutFilter/setInputDateAfter', value);
    },
    updateDateBefore(e) {
      const { target: { value } } = e;
      this.$store.dispatch('workoutFilter/setInputDateBefore', value);
    },
    updateSport(sport) {
      this.$store.dispatch('workoutFilter/setSport', sport);
    },
    handleApply() {
      this.$store.dispatch('workoutFilter/setPending');
      this.$emit('apply');
    },
    handleCancel() {
      this.$store.dispatch('workoutFilter/setPending');
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~bulma';
  .progress {
    width: 100px;
  }
  .dates-separator {
    display:none;

    @include from($tablet) {
      display: block;
    }
  }
</style>
