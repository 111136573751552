import sha256 from 'hash.js/lib/hash/sha/256';

export default async (requestBuilder, next) => {
  if (requestBuilder.options.method !== 'GET') {
    throw new Error('Only GET requests are cacheable');
  }
  const cacheKey = `cache_${sha256().update(requestBuilder.url).digest('hex')}`;
  const cached = JSON.parse(window.localStorage.getItem(cacheKey));

  if (cached) {
    // do not freeze the browser if everything is cached
    await new Promise((resolve) => setTimeout(resolve, 0));
    return cached;
  }

  const response = await next();
  window.localStorage.setItem(cacheKey, JSON.stringify(response));
  return response;
};
