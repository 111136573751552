<template>
  <div class="dropdown ml-1 is-flex-grow-1" :class="{ 'is-active': isActive }">
    <div class="dropdown-trigger is-flex-grow-1" @click.stop="trigger">
      <button class="button is-fullwidth"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span class="sport-picker-label">{{ sport ? sport.displayName : allLabel }}</span>
        <span class="icon is-small">
           <font-awesome-icon icon="angle-down"></font-awesome-icon>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          href="#"
          class="dropdown-item has-text-weight-bold"
          @click.stop="select(null)"
        >
          {{ allLabel }}
        </a>
        <a
          v-for="item in items"
          :key="item.name"
          href="#"
          class="dropdown-item"
          :style="{ color: item.color }"
          @click.stop="select(item)"
        >
          {{ item.displayName }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SportPicker',
  props: ['sport'],
  data() {
    return {
      isActive: false,
    };
  },
  created() {
    this.allLabel = 'All';
    this.$store.dispatch('sport/getAll');
  },
  computed: {
    ...mapState('sport', ['items']),
  },
  methods: {
    trigger() {
      this.isActive = !this.isActive;
    },
    select(item) {
      this.isActive = false;
      this.$emit('selected', item);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~bulma';

  .sport-picker-label {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include from($tablet) {
      width:150px;
    }
  }
</style>
