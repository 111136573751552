import { Loader } from 'google-maps';

// TODO
const apiKey = 'AIzaSyAMesOeUsx2FHMNQJTN172u4ww4Kp-12cw';

export default {
  google: null,
  map: null,
  polylines: [],
  popup: null,
  async loadSdk() {
    const options = {
      libraries: ['geometry'],
    };
    const loader = new Loader(apiKey, options);
    return loader.load();
  },
  async init(containerId, lat, lng, zoom) {
    if (!this.google) {
      this.google = await this.loadSdk();
    }
    this.map = new this.google.maps.Map(document.getElementById(containerId), {
      center: { lat, lng },
      zoom,
    });
  },
  addEncodedPolyline(encodedPolyline, color) {
    const polylineObj = new this.google.maps.Polyline({
      path: this.google.maps.geometry.encoding.decodePath(encodedPolyline),
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    polylineObj.setMap(this.map);
    this.polylines.push(polylineObj);

    return polylineObj;
  },
  bindViewToPolyline(polylineObj) {
    const bounds = new this.google.maps.LatLngBounds();
    polylineObj.getPath().forEach(
      (coords) => bounds.extend(new this.google.maps.LatLng(coords.lat(), coords.lng())),
    );
    this.map.fitBounds(bounds);
  },
  addPopup(clickableObj, content) {
    this.google.maps.event.addListener(clickableObj, 'click', (event) => {
      if (this.popup) {
        this.popup.close();
      }

      this.popup = new this.google.maps.InfoWindow({
        content,
        position: event.latLng,
      });
      this.popup.open(this.map);
    });
  },
  clear() {
    this.polylines.forEach((polylineObj) => polylineObj.setMap(null));
    this.polylines = [];
  },
  destroy() {
    this.map = null;
  },
};
